
import { Component, Vue } from 'vue-property-decorator'
import { CarMaintainInfo } from '@/types/carManage'

@Component({
  name: 'CarManageMaintainList'
})
export default class extends Vue {
  private info = {
    projectName: '',
    vehicleName: '',
    vehicleType: '',
    startTime: '',
    endTime: ''
  }

  private dateArr: string[] = []

  private page = 1
  private size = 10
  private total = 0

  private loading = false
  private tableData: CarMaintainInfo[] = []
  private carTypeList = []
  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getList()
    this.getCarTypeList()
  }

  onSearch () {
    this.page = 1
    this.getList()
  }

  getList () {
    this.loading = true
    this.$axios.get<{total: number; list: CarMaintainInfo[]}>(this.$apis.carManage.selectYhVehicleRepairByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      if (res) {
        this.total = res.total || 0
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  // 车辆类型
  getCarTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'vehicleType' }).then(res => {
      this.carTypeList = res.vehicleType || []
    })
  }

  // 删除
  onDelete (repairId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.loading = true
        this.$axios.post(this.$apis.carManage.deleteYhVehicleRepair, { repairId }).then(() => {
          this.$message.success('删除成功')
          this.getList()
        }).finally(() => {
          this.loading = false
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  dateChange (date: string[]) {
    if (date) {
      this.info.startTime = date[0]
      this.info.endTime = date[1]
    } else {
      this.info.startTime = ''
      this.info.endTime = ''
    }
  }

  // 新增车辆
  onAdd () {
    this.$router.push({ name: 'addMaintain' })
  }

  onEdit (id: string) {
    this.$router.push({
      name: 'editMaintain',
      params: { id }
    })
  }

  // 查看
  onDetail (id: string) {
    this.$router.push({
      name: 'detailMaintain',
      params: {
        id
      }
    })
  }

  getState (state: string) {
    const arr = ['', '未开工', '在建', '已移交']
    return arr[Number(state)]
  }
}
